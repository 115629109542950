#sale.page {
    .tab-header {
        span {
            width: calc(100% / 5);
        }

        .animated-bg {
            width: calc(100% / 5);
        }
    }
}
