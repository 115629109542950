#project.page {
    .page-content {
        .key-metrics, .project-details, .project-highlights, .social-links, .markets {
            .title {
                &.nospace {
                    padding: 0 0 1rem;
                }
                padding: 3rem 0 1rem 0;
                border-bottom: 1px solid white;
    
                span {
                    @include secondaryFont;
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 25px;
                }
            }
    
            .content {
                span {
                    @include secondaryFont;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 21px;
                }
            }
        }

        .project-details {
            .content {
                padding: 2rem 0;

                .project-toggle {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: .5rem;
                }
            }

            textarea {
                height: 700px;
            }
        }

        .project-highlights {
            .content {
                display: grid;
                flex-direction: column;
                grid-row-gap: 1.5rem;
                padding: 2rem 0;
    
                .logo-wrapper {
                    display: grid;
                    align-items: center;
                    grid-template-columns: 3fr 6fr 1fr;
                    grid-column-gap: 2rem;
    
                    .logo-preview {
                        max-height: 90px;
    
                        img {
                            height: 80px;
                            contain: content;
                        }
                    }
                }
            }
        }

        .key-metrics {
            .content {
                display: grid;
                grid-row-gap: 1.5rem;
                padding: 2rem 0;
            }
        }

        .social-links, .markets {
            .content {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 2rem;
                grid-row-gap: 1.5rem;
                padding: 2rem 0;
            }

            .site-wrapper {
                display: flex;
                align-items: center;
                column-gap: .2rem;

                svg {
                    margin-left: .5rem;
                }
            }
        }
    }

    .details-preview {
        padding-top: .5rem;
        position: relative;
        width: 100%;

        & > div {
            border-radius: 15px;
            height: 670px;
        }

        .details-nav {
            display: block;

            > div {
                height: 100%;
                overflow: auto;
                scroll-behavior: smooth;

                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: $bgNavbar;
                    border-radius: 3px;
                }
            }
            

            @include breakpoint(phablet) {
                display: none;
            }

            cursor: pointer;
            width: 35%;
            padding: 2.5rem 4.5rem 2.5rem 1.5rem;
            z-index: 1;
            background: #181f35;

            .details-navitem {
                padding: 0.5rem 0;
                color: #11a3b7;
                font-weight: 700;
                border-bottom: 1px dashed #bac6d2;

                &.subtitle {
                    font-weight: normal;
                    margin-left: 1.5rem;
                }
            }
        }

        .details-select {
            display: none;

            @include breakpoint(phablet) {
                display: block;
                height: fit-content;
                padding: 2rem 0;
            }
        }

        .details-content {
            margin-top: .5rem;
            padding: 2.5rem 2rem;
            width: 70%;
            z-index: 0;
            background-color: $bgNavbar;
            box-shadow: -10px 0px 30px rgba(0, 0, 0, 0.15);
            position: absolute;
            right: 0;
            top: 0;

            @include breakpoint(phablet) {
                width: 100%;
                background-color: inherit;
                box-shadow: none;
                padding: 2.5rem 0;
                position: relative;
            }

            & > div {
                scroll-behavior: smooth;
                width: 100%;
                height: 100%;
                overflow: auto;

                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: $bgPrimary;
                    border-radius: 3px;
                }
            }

            .markdown {
                @include secondaryFont;

                h1 {
                    line-height: 3.3rem;
                }

                h2 {
                    line-height: 2.8rem;
                    padding-left: .5rem;
                }

                p {
                    line-height: 1.4rem;
                    padding-left: 1rem;
                }
            }

            .content-title {
                font-size: 18px;
                font-weight: 700;
                margin: 1rem 0;
            }

            .content {
                padding: 0;
                p {
                    word-break: break-all;
                    white-space: pre-wrap;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
}
