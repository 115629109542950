.schedule-page {
    .whitelist-period {
        .content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 2rem;
            grid-row-gap: 1.5rem;
            padding: 2rem 0;

            .open-date, .close-date {
                .info-mark-wrapper {
                    display: flex;
                    column-gap: .5rem;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }
    }

    .sale-period {
        .content {
            display: grid;
            padding: 2rem 0;
            grid-row-gap: 1.5rem;

            .simple-btn-wrapper {
                height: 48px;
                border-radius: .5rem;

                &.remove {
                    background: $redGradientColor !important;
                }
            }
        }
    }

    .sale-contract-info {
        .content {
            padding: 2rem 0;
            .sub-wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 2rem;
                grid-row-gap: 1.5rem;
                padding-bottom: 2rem;
            }

            .padding-bottom {
                padding-bottom: 2rem;
            }
        }
    }
}
