.project-page {
    .prompt {
        @include secondaryFont;
        @include flexWrapper;
        @include alignItemsCenter;
        @include justifyContentCenter;
        height: 500px;
        font-size: 30px;
    }
}

.project-table {
    .sort-table-row {
        grid-template-columns: 4fr 2fr 3fr 2fr 2fr 1fr;
    }
}

.sale-table {
    .sort-table-row {
        grid-template-columns: 4fr 2fr 3fr 2.5fr 3fr 3fr 3fr 2fr;
    }
}

.lmpool-table {
    .sort-table-row {
        grid-template-columns: 5fr 4fr 2fr 2fr 3fr 3fr 2fr;
    }
}

.requests-page {
    .toggle-switch {
        span {
            font-size: 1rem;
            margin-right: .5rem;
        }
    }

    .request-table {
        .sort-table-row {
            grid-template-columns: 1fr 1fr 1fr 1.5fr;
        }
    
        .sort-table-cell:nth-last-child(2) {
            justify-content: left !important;
        }
    
        .sort-table-cell:last-child {
            column-gap: 1rem;
        }
    }
}

.quotes-page {
    .toggle-switch {
        span {
            font-size: 1rem;
            margin-right: .5rem;
        }
    }

    .quotes-table {
        .sort-table-row {
            grid-template-columns: 4fr 4fr 4fr 2fr;

            .sort-table-cell:nth-last-child(2) {
                justify-content: left !important;
            }
        
            .sort-table-cell:last-child {
                justify-content: center;

                .simple-btn-wrapper {
                    width: fit-content;
                    padding: 0 1.5rem;
                }
            }
        }
    }
}


.accounts-page {
    .page-table-header {
        .actions-wrapper {
            display: flex;
            align-items: center;

            .toggle-switch {
                span {
                    font-size: 1rem;
                    margin-right: .5rem;
                }
            }
    
            .simple-btn-wrapper {
                margin-left: 1rem;
                width: fit-content;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
        
    }

    .accounts-table {
        .sort-table-row {
            grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1fr;
            .sort-table-cell:nth-last-child(2) {
                @include justifyContentStart;
            }

            .sort-table-cell:last-child {
                column-gap: .5rem;
            }
        }
    }
}



.dashboard {
    .sale-pool-container {
        padding: 2rem 0;

        .content-header-wrapper {
            position: relative;
        }

        .content-body {
            padding: 2rem 0;

            &.with-children {
                display: flex;
                justify-items: center;
                justify-content: space-between;
            }

            .live-card {
                @include secondaryFont;
                display: grid;
                grid-row-gap: 1rem;
                width: 100%;
                max-width: 370px;
                background: rgba(26, 34, 56, 0.8);
                box-shadow: -10px -10px 15px rgba(50, 64, 102, 0.3), 10px 10px 15px rgba(1, 7, 19, 0.3);
                border-radius: 20px;

                .project-view {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    border-radius: 20px;
                    background: $bgNavbar;
                    padding: 2rem;
                    position: relative;
                    overflow: hidden;

                    .collapse-wrapper {
                        cursor: pointer;
                        display: none;
                    }

                    &:before {
                        position: absolute;
                        content: '';
                        background: linear-gradient(271.01deg, #616f94 -9.5%, rgba(36, 46, 71, 0) 37.88%);
                        opacity: 0.3;
                        width: 1000px;
                        height: 700px;
                        left: -690px;
                        top: -565px;
                        border-radius: 50%;
                        z-index: 0;
                    }

                    .project-logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        z-index: 1;

                        img {
                            width: 72px;
                            height: 72px;
                        }
                    }

                    .project-info {
                        margin-left: 0.5rem;

                        .project-title {
                            font-size: 20px;
                        }

                        .social-wrapper {
                            margin-top: 0.5rem;
                            display: grid;
                            grid-template-columns: repeat(4, 1fr);
                            column-gap: 1rem;

                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }

                    .info-toggle {
                        background: linear-gradient(118.15deg, #25beb4 -236.43%, rgba(37, 190, 180, 0) 117.03%);
                        border-radius: 0px 20px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        display: flex;
                        padding: 0.5rem;
                        z-index: 3;
                        color: #ffffff99;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }

                .project-content {
                    padding: 2rem;
                    display: grid;
                    flex-direction: column;
                    grid-row-gap: 2rem;

                    @include transition;

                    &.can-collapse {
                        &.collapsed {
                            max-height: 0;
                            height: 0;
                            opacity: 0;
                            padding: 0;
                        }
                    }

                    .price-wrapper {
                        .price-info {
                            display: grid;
                            grid-template-columns: 4fr 7fr;
                            align-items: end;

                            > span:first-child {
                                color: #5e6986;
                                text-align: right;
                                font-size: 12px;
                            }

                            > span:last-child {
                                font-size: 14px;
                                margin-left: 0.5rem;
                            }

                            &:last-child {
                                margin-top: 0.5rem;
                            }
                        }
                    }

                    .footer-container {
                        display: flex;
                        justify-content: space-evenly;
                    }
                }
            }
        }

        .overview {
            @include flexWrapper;
            position: relative;
            border-radius: 20px;
            box-shadow: -10px -10px 20px rgba(50, 64, 102, 0.2), 10px 10px 20px rgba(1, 7, 19, 0.2);

            .left-panel {
                display: grid;
                flex-direction: column;
                align-content: space-between;
                position: relative;
                min-width: 370px;
                background: rgba(52, 168, 234, 0.05);
                border-radius: 20px;
                z-index: 1;
                padding: 2rem;
                overflow: hidden;

                .logo-wrapper {
                    display: flex;
                    align-items: center;
                    column-gap: 0.5rem;

                    span {
                        @include secondaryFont;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24px;
                    }
                }

                .token-info {
                    @include secondaryFont;
                    display: flex;
                    flex-direction: column;
                    margin-top: 1rem;

                    .label {
                        font-size: 12px;
                        color: $primaryColor;
                    }

                    .value {
                        font-size: 18px;
                        font-weight: 500;
                    }
                }

                .logo-back {
                    position: absolute;
                    right: 35px;
                    top: 50px;

                    svg {
                        path,
                        rect {
                            fill: #ffffff;
                            opacity: 0.03;
                        }
                    }
                }

                &:before {
                    position: absolute;
                    content: '';
                    width: 1000px;
                    height: 2000px;
                    background: linear-gradient(271.01deg, #616f94 -9.5%, rgba(36, 46, 71, 0) 37.88%);
                    opacity: 0.3;
                    transform: rotate(-90deg);
                    border-radius: 50%;
                    top: -525px;
                    right: 451px;
                    z-index: -1;
                }
            }

            .right-panel {
                flex: auto;
                position: relative;
                padding: 2rem;
                z-index: 1;
                border-radius: 0 20px 20px 0;

                .overview-info {
                    display: grid;
                    grid-template-columns: minmax(0, 1fr);
                    justify-content: center;
                    grid-row-gap: 2rem;
                    height: 100%;
                    z-index: 2;
                    position: relative;

                    .info-container {
                        display: grid;
                        grid-row-gap: 0.5rem;

                        h2 {
                            @include secondaryFont;
                            font-size: 20px;
                            font-weight: 500;
                        }

                        span {
                            font-size: 14px;
                        }

                        .overview-detail {
                            text-align: justify;
                        }

                        .item-container {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            width: 100%;
                            grid-row-gap: 0.5rem;
                            grid-column-gap: 2rem;

                            .item {
                                display: flex;
                                align-items: flex-end;
                                width: 100%;

                                .space-gap {
                                    flex: auto;
                                    border-bottom: 1px dashed rgba(186, 198, 210, 0.5);
                                    height: 80%;
                                    margin: 0 4px 3px;
                                }

                                .value {
                                    color: $primaryColor;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }

                &:after {
                    position: absolute;
                    content: '';
                    border-radius: 20px;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-image: url('/assets/png/ido-mask.webp');
                    background-size: cover;
                    opacity: 0.09;
                    z-index: 1;

                    display: block;
                }

                .social-wrapper {
                    position: absolute;
                    right: 0;
                    top: 0;
                    border-radius: 0 20px 0 20px;
                    background: linear-gradient(
                        118.15deg,
                        rgba(39, 19, 60, 0.4) -236.43%,
                        rgba(122, 38, 151, 0.1) 117.03%
                    );
                    display: flex;
                    align-items: center;
                    padding: 10px 30px;
                    z-index: 3;

                    svg {
                        width: 18px;
                        height: 18px;
                    }

                    a:last-child {
                        margin-right: 0;
                    }

                    a {
                        @include transition;
                        opacity: 0.6;
                        margin-right: 0.8rem;
                        @include flexWrapper;
                        @include alignItemsCenter;
                        @include justifyContentCenter;

                        &:hover {
                            opacity: 0.9;
                        }
                    }
                }
            }

            &:before {
                position: absolute;
                content: '';
                border-radius: 20px;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba(52, 168, 234, 0.05);
                z-index: 0;
            }

            .liveprogress-wrapper {
                margin: 0;
                padding: 0;
            }
        }
    }
}