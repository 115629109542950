.spinner-wrapper {
    padding-top: 4px;
    margin-left: 1.5rem;
}

.btn-wrapper {
    @include flexWrapper;
    @include justifyContentCenter;
    @include alignItemsCenter;
    @include transition;

    white-space: nowrap;

    width: 100%;
    height: 36px;
    border: none;
    border-radius: 24px;
    color: $textColor;
    cursor: pointer;
    padding: 0;

    &:hover {
        opacity: 0.8;
    }

    &:disabled:hover {
        cursor: default !important;
    }

    &.default-cursor {
        cursor: default !important;
    }
}

.btn-box-shadow {
    box-shadow: 0px 0px 0px 1.6px #1a2238, -4px -4px 8px rgba(255, 255, 255, 0.1), 4px 8px 8px rgba(1, 7, 19, 0.2);
}

.round-btn-wrapper {
    border-radius: 50%;
    width: 36px;
    flex-shrink: 0;

    background: $secondaryGradientColor;

    svg {
        fill: $textColor;
    }
}

.publish-btn-wrapper {
    background: $cyanGradientColor !important;
    border-radius: 14px !important;
    height: 60px !important;
    font-size: 16px;
    font-weight: 700;
}

.simple-btn-wrapper {
    background: $primaryGradientColor;
    position: relative;

    &.empty {
        background: none !important;
        color: $primaryColor;
        border: 2px solid $primaryColor;
        background-color: $primaryColor;
    }

    &.secondary-btn {
        background: $secondaryGradientColor !important;
        color: $textColor;
        border: none;
    }

    &.third-btn {
        background: $redGradientColor !important;
    }

    &.outlined {
        background: none;
        color: $textColor;
        border: 1px solid $primaryColor;

        &:hover {
            background: rgba($primaryColor, 0.2);
        }

        svg {
            width: 11px !important;
            height: 11px !important;
        }
    }

    a {
        width: 100%;
        height: 100%;
        @include flexWrapper;
        @include alignItemsCenter;
        @include justifyContentCenter;
    }

    &.has-next-arrow {
        svg {
            position: absolute;
            right: 3px;
            top: 3px;
        }
    }

    &:disabled {
        opacity: 0.6;
    }
}

.add-token-btn-wrapper {
    background: none;
    border: 1px solid white;

    padding: 1rem;
    margin-right: 1rem;

    span {
        padding: 0 0.5rem;
        color: white;
        font-size: 14px;
    }
}

.kyc-wrapper {
    margin-right: 1rem;
    padding: 0 0.3rem;
    width: fit-content;

    &.gray-btn {
        background: $bgGray;
    }

    &.red-btn {
        background: $redGradientColor;
    }

    &.cyan-btn {
        background: $primaryGradientColor;
    }

    span {
        padding: 0 0.5rem;
    }

    @include breakpoint(mobile) {
        margin-right: 0.5rem;
    }

    @include breakpoint(phablet) {
        span {
            display: none;
        }
    }
}

.wallet-status-wrapper {
    .btn-wrapper {
        width: 180px;

        span {
            padding: 0 1rem;
        }

        img {
            max-width: 28px;
        }

        @include breakpoint(phablet) {
            width: 36px !important;

            span {
                display: none;
            }
        }
    }

    border-radius: 24px;
    background: #10172a;
    box-shadow: 0px 0px 0px 1.6px #1a2238, -4px -4px 8px rgba(255, 255, 255, 0.1), 4px 8px 8px rgba(1, 7, 19, 0.2);
    @include flexWrapper;
    justify-content: space-between;
    @include alignItemsCenter;

    .btn-wrapper {
        background: $secondaryGradientColor;
        padding: 0 1rem;
        width: fit-content;

        span {
            padding: 0 0.3rem 0 0.7rem;
        }
    }

    .wallet-info {
        margin: 0 1rem;

        @include breakpoint(mobile) {
            padding: 0 !important;
            margin: 0 0.5rem;
        }
    }
}

.progress-bar-wrapper {
    .rootDiv {
        height: 30px;

        &.board {
            height: 20px;
        }

        @include breakpoint(phablet) {
            height: 20px;
        }
    }

    .title-bar {
        @include flexWrapper;
        @include justifyContentSpaceBetween;
        margin: 1rem 0;
        color: $primaryColor;
        font-size: 20px;
        font-weight: bold;

        &.board {
            color: #fff;
            font-size: 15px;
        }

        @include breakpoint(phablet) {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .token-status-bar {
        @include flexWrapper;
        @include justifyContentSpaceBetween;
        margin: 1rem 0;

        font-size: 20px;
        font-weight: 500;

        &.board {
            font-size: 15px;
        }

        @include breakpoint(phablet) {
            font-size: 13px;
        }
    }
}

.custom-tooltip {
    font-size: 14px !important;
    padding: 0.7rem 1.5rem !important;
    border-radius: 20px !important;
    border: 2px solid #10172a !important;
    border-radius: 20px;
    box-shadow: 0px 0px 0px 1.6px #1a2238, -4px -4px 8px rgba(255, 255, 255, 0.1), 4px 8px 8px rgba(1, 7, 19, 0.2) !important;
    background: linear-gradient(135deg, #35405b 0%, #222c45 100%) !important;
    max-width: 100%;
    white-space: pre;
    opacity: 1 !important;

    &.place-top {
        margin-top: 0px !important;

        &:after {
            bottom: -17px !important;
            top: unset !important;
            border-top-color: #10172a !important;
            border-top-width: 15px !important;
            border-left-width: 10px !important;
            border-right-width: 10px !important;
        }
    }

    &.place-bottom {
        margin-top: 20px !important;

        &:after {
            top: -15px !important;
            border-bottom-color: #10172a !important;
            border-bottom-width: 15px !important;
            border-left-width: 10px !important;
            border-right-width: 10px !important;
        }
    }

    &.place-left {
        &:after {
            right: -15px !important;
            top: calc(50% - 5px) !important;
            border-left-color: #10172a !important;
            border-left-width: 15px !important;
            border-top-width: 10px !important;
            border-bottom-width: 10px !important;
        }
    }

    &.place-right {
        margin-left: 20px !important;

        &:after {
            left: -15px !important;
            top: calc(50% - 5px) !important;
            border-right-color: #10172a !important;
            border-right-width: 15px !important;
            border-top-width: 10px !important;
            border-bottom-width: 10px !important;
        }
    }
}

.control-button {
    box-shadow: 0px 0px 0px 2px #1a2238, -5px -5px 10px rgba(255, 255, 255, 0.1), 5px 10px 10px rgba(1, 7, 19, 0.2);
    background: $bgPrimary;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 70px;

    .text-info {
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        line-height: 35px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        height: 50%;
    }

    .button-status {
        width: 80%;
        height: 50%;
        border-bottom: 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 32px;
        }
    }

    // .btn-wrapper {
    //     column-gap: 0.5rem;
    // }

    &.Closed {
        background: linear-gradient(110.05deg, #fd7575 13.06%, rgba(253, 117, 117, 0.5) 84.95%);
        box-shadow: 0px 0px 0px 2px #1a2238, -5px -5px 10px rgba(255, 255, 255, 0.1), 5px 10px 10px rgba(1, 7, 19, 0.2);
    }
}

.countdown-wrapper {
    &.outside {
        box-shadow: 0px 0px 0px 2px #1a2238, -5px -5px 10px rgba(255, 255, 255, 0.1), 5px 10px 10px rgba(1, 7, 19, 0.2);
        background: $bgPrimary;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 70px;
    }

    .header {
        display: flex;
        justify-content: center;

        span {
            font-size: 12px;

            &:nth-of-type(2) {
                color: $primaryColor;
                margin-left: 0.5rem;
            }
        }

        &.expired {
            justify-content: center;

            span:first-of-type {
                color: #98a2ac;
            }
        }
    }

    .time {
        width: 100%;
        display: flex;
        justify-content: center;
        background: linear-gradient(180deg, #060b19 0%, #181f34 100%);
        box-shadow: 0px 0px 0px 2px #1a2238, -5px -5px 10px rgba(255, 255, 255, 0.1), 5px 10px 10px rgba(1, 7, 19, 0.2);
        border-radius: 20px;
        padding: 0.5rem 2rem;
        margin-top: 0.5rem;

        &.board {
            margin-top: 0;
        }

        p:last-child {
            margin-right: 0;
        }

        p {
            color: $timeColor;
            font-weight: 500;
            font-size: 16px;
            display: flex;
            align-items: baseline;
            margin-right: 2rem;

            @include breakpoint(laptop) {
                margin-right: 1rem;
            }

            sub {
                font-size: 10px;
            }
        }
    }

    .footer {
        margin: 0.4rem 0;

        span {
            font-size: 10px;
            line-height: 13px;
        }
    }
}

.simple-input-container {
    position: relative;
    height: 48px;
    width: 100%;
    margin: 0.5rem 0;

    @include transition;

    input {
        @include secondaryFont;
        background: #242f4e;
        border-radius: 10px;
        border: unset;
        outline: none;
        color: $grayText;
        height: 48px;
        padding: 0 0.5rem 0 1.5rem;
        width: 100%;
        font-size: 14px;
        font-weight: 400;

        &:read-only {
            opacity: 0.7;
        }
    }

    .address-scan-container {
        position: absolute;
        right: 1rem;
        top: 10px;
        cursor: pointer;
        opacity: 0.5;

        img {
            width: 24px;
        }
    }

    .protector-lock {
        position: absolute;
        right: 1rem;
        top: 10px;
        cursor: pointer;
        opacity: 0.5;

        img {
            width: 24px;
        }
    }

    .prefix-container {
        position: absolute;
        left: 1rem;
        top: 0;
        height: 48px;
        display: flex;
        align-items: center;

        span {
            @include secondaryFont;
            color: white;
            line-height: 48px !important;
            font-size: 14px !important;
        }
    }
}

.select__control--is-focused {
    border: none !important;
    box-shadow: none !important;
}

.select__menu {
    div {
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: $grayText;
            border-radius: 3px;
        }
    }
}

.select__indicator-separator {
    // visibility: hidden;
    background-color: $grayText !important;
}

.toggle-switch {
    display: flex;
    align-items: center;
    position: relative;

    span {
        font-size: 18px;
        font-weight: 500;
        color: white;
        margin-right: 20px;
    }

    .switch-area {
        background: #3c4c60;
        border-radius: 100px;
        position: relative;
        width: 50px;
        height: 25px;
        @include transition();
        cursor: pointer;

        &::before {
            @include transition();
            position: absolute;
            content: '';
            background: #fff;
            box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);
            border-radius: 32px;
            width: 19px;
            height: 19px;
            top: 3px;
            left: 3px;
        }
    }

    &.active {
        .switch-area {
            background: $primaryColor;

            &::before {
                @include transition();
                transform: translateX(25px);
            }
        }
    }
}

.custom-textarea-wrapper {
    margin: 0.5rem 0;

    textarea {
        @include transition;
        @include secondaryFont;
        background: #242f4e;
        border-radius: 13px;
        resize: none;
        width: 100%;
        height: 80px;
        padding: 1rem 0.5em 1rem 1.5em;
        margin: 0;
        border: none;

        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: white;

        &:focus-visible {
            outline: none;
        }

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: $bgPrimary;
        }

        &::-webkit-scrollbar-thumb {
            background: $grayText;
            border-radius: 3px;
        }
    }
}

.custom-date-picker-wrapper {
    position: relative;

    .react-datepicker-wrapper {
        height: 48px;
        width: 100%;
        margin: 0.5rem 0;
        transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
        right: 0;

        .react-datepicker__input-container {
            input {
                @include secondaryFont;
                background: #242f4e;
                border-radius: 10px;
                border: unset;
                outline: none;
                color: #687c92;
                height: 48px;
                padding: 0 3.5rem 0 1.5rem;
                width: 100%;
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;

                &:disabled {
                    opacity: 0.7;
                }
            }
        }
    }

    .react-datepicker__tab-loop {
        .react-datepicker {
            background: #242f4e;
            border-radius: 13px;
            border: none;
            box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.5);
            padding: 1rem;

            .react-datepicker__navigation {
                top: 1rem;

                &.react-datepicker__navigation--previous {
                    left: 1rem;
                }

                &.react-datepicker__navigation--next {
                    right: 1rem;
                }
            }

            .react-datepicker__triangle {
                display: none;

                &::before,
                &::after {
                    right: 140px;
                    border-bottom-color: $bgNavbar;
                    border-top-color: $bgNavbar;
                }
            }

            .react-datepicker__month-container {
                .react-datepicker__header {
                    background: #242f4e;
                    border-bottom: none;
                    border-radius: 13px;

                    .react-datepicker__current-month {
                        color: white;
                    }

                    .react-datepicker__day-names {
                        .react-datepicker__day-name {
                            @include secondaryFont;
                            color: white;
                        }
                    }
                }

                .react-datepicker__month {
                    .react-datepicker__day {
                        @include secondaryFont;
                        color: white;
                        background: linear-gradient(
                            160.34deg,
                            rgba(255, 255, 255, 0.4) -20%,
                            rgba(255, 255, 255, 0.1) 88.34%
                        );
                        backdrop-filter: blur(100px);
                        border-radius: 7px;

                        font-weight: 400;
                        font-size: 12px;

                        &.react-datepicker__day--selected {
                            background: linear-gradient(95.58deg, #b5c1cd 5.52%, #98a2ac 100%);
                            color: black;
                        }

                        &.react-datepicker__day--disabled {
                            background: #45494c;
                        }
                    }
                }
            }

            .react-datepicker__input-time-container {
                padding: 1rem 2rem;
                margin: 0;
                float: none;

                .react-datepicker-time__input {
                    input {
                        @include secondaryFont;
                        font-weight: 400;
                        font-size: 14px;
                        color: white !important;
                        background: #181f35;
                        border-radius: 10px;
                        padding: 0.1rem 1rem;
                        border: unset;

                        &:focus-visible {
                            outline: none;
                        }
                    }
                }

                .react-datepicker-time__caption {
                    color: white;
                    @include secondaryFont;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
    }

    .suffix-container {
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 22px;
        display: flex;
        align-items: center;
    }
}

.content-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    > svg {
        width: 24px;
        height: 24px;
        margin-right: 0.5rem;

        path,
        rect {
            fill: $primaryColor;
        }
    }

    > span {
        @include secondaryFont;
        margin-right: 0.5rem;
        color: $primaryColor;
        font-weight: 700;
        font-size: 24px;
    }

    .space-gap {
        flex: auto;
        border-bottom: 1px dashed rgba(186, 198, 210, 0.5);
        height: 16px;
    }
}

.horizontal-timeline-wrapper {
    @include flexWrapper;
    margin: 2rem 0 0;

    .pool-timeline-container,
    .public-timeline-container,
    .private-timeline-container,
    .horizontal-timeline-container {
        @include flexWrapper;
        @include flexColumn;

        &.leftAlign {
            @include alignItemsStart;
        }

        &.centerAlign {
            @include alignItemsCenter;
        }

        &.rightAlign {
            @include alignItemsEnd;
        }

        div {
            &.Passed {
                background: #3bdcb1;
            }

            &.Notpassed {
                background: #c4c4c4;
            }

            &.Completed {
                background: #11a3b7;
            }

            &.Ongoing {
                background: #3bdcb1;
            }

            &.Upcoming {
                background: #bac6d2;
            }
        }

        .title {
            @include secondaryFont;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            white-space: nowrap;
        }

        .detail {
            @include flexWrapper;
            @include flexColumn;
            @include alignItemsCenter;

            &.leftAlign {
                @include alignItemsStart;
            }

            &.centerAlign {
                @include alignItemsCenter;
            }

            &.rightAlign {
                @include alignItemsEnd;
            }

            .detail-info {
                font-size: 14px;
                line-height: 18px;
            }
        }

        .spot {
            @include flexWrapper;
            @include alignItemsCenter;

            width: 100%;
            margin: 0.5rem 0;

            .timeline-bar {
                height: 3px;
                width: 100%;
            }

            .timeline-spot {
                min-width: 30px;
                min-height: 30px;
                border-radius: 99px;
            }
        }
    }

    .public-timeline-container,
    .horizontal-timeline-container {
        margin: 0 2px;
    }

    .pool-timeline-container,
    .private-timeline-container {
        margin: 0;
    }
}

span.disabled {
    opacity: 0.5;
}

.image-upload-wrapper {
    @include secondaryFont;
    @include flexWrapper;
    justify-content: space-between;
    @include alignItemsCenter;
    border-radius: 13px;
    background-color: $bgNavbar;
    margin: 0.5rem 0;

    .filename-wrapper {
        margin: 0.7rem 1.5rem;
        color: $grayText;
        font-size: 14px;
        font-weight: 400;

        &.white {
            color: white !important;
        }
    }

    &.has-error {
        color: white !important;
        background: $redGradientColor;

        .filename-wrapper {
            color: white !important;
        }
    }

    button {
        @include flexWrapper;
        @include alignItemsCenter;
        background: $cyanGradientColor;
        padding: 0.7rem 2rem;
        border: none;
        color: $textColor;
        border-radius: 13px;
        height: 48px;
        cursor: pointer;
        @include transition;

        span {
            @include secondaryFont;
            font-weight: 700;
            font-size: 14px;
        }

        img {
            margin-left: 1rem;
        }

        &:hover {
            opacity: 0.8;
        }

        &:disabled {
            opacity: 0.6;
            cursor: default;
        }
    }
}

.waiting-wrapper {
    @include secondaryFont;
    &.show-wrapper {
        display: block;
    }

    display: none;
    position: fixed;
    top: 100px;
    right: 100px;
    padding: 0.5rem 1rem;
    width: 240px;

    font-size: 16px;
    font-weight: 400;
    border-radius: 10px;
    background: linear-gradient(135deg, #35405b 0%, #222c45 100%) !important;
    border: 2px solid #47596c;

    span {
        white-space: pre;
    }
}
