.whitelist-page {
    overflow: hidden;
    .project-description, .social-links, .questions, .whitelist-period, .whitelist-users, .restrict {
        .horizontal-divider {
            margin: 0;
        }

        .content {
            padding: 2rem 0;
            display: grid;
            grid-row-gap: 1.5rem;
        }
    }

    .whitelist-period {
        .two-child-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 1.5rem;
        }
    }

    .whitelist-users {
        
    }
    
    .social-links {
        .content {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 2rem;
        }
    }

    .questions {
        .content {
            span {
                &.correct {
                    color: #3BDCB1;
                }

                &.incorrect {
                    color: #FD7575;
                }
            }
        }
    }

    .project-description {
        .project-toggle {
            display: flex;
            justify-content: space-between;
            padding-bottom: .5rem;
        }

        textarea {
            height: 250px;
        }

        textarea, .markdown {
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: #0a1020;
                border-radius: 3px;
            }
        }

        .markdown {
            @include secondaryFont;
            margin: 0.5rem 0;
            padding: 2.5rem 2rem;
            border-radius: 15px;
            background-color: #181f35;
            box-shadow: -10px 0px 30px rgba(0, 0, 0, .15);
            height: 250px;
            overflow: auto;

            h1 {
                line-height: 3.3rem;
            }

            h2 {
                line-height: 2.8rem;
                padding-left: .5rem;
            }

            p {
                line-height: 1.4rem;
                padding-left: 1rem;
            }
        }
    }

    .restrict {
        .content {
            display: block;
            min-height: 300px;
        }
    }

    .react-tags {
        background: #242F4E;
        position: relative;
        padding: .5rem;
        border-radius: 10px;
      
        /* shared font styles */
        font-size: 1em;
        line-height: 1.2;
      
        /* clicking anywhere will focus the input */
        cursor: text;
    }
    
    .react-tags__selected {
        display: inline;

        &-tag {
            display: inline-block;
            box-sizing: border-box;
            padding: .5rem;
            margin: .2rem;
            border: none;
            border-radius: 15px;
            background: #182342;
            
            /* match the font styles */
            font-size: inherit;
            line-height: inherit;

            &:after {
                content: '\2715';
                color: #AAA;
                margin-left: 8px;
                cursor: pointer;
            }

            &-name {
                color: white;
                font-size: 14px;
            }
        }
    }
    
    .react-tags__search {
        display: inline-block;
        
        /* match tag layout */
        padding: 7px 2px;
        
        /* prevent autoresize overflowing the container */
        max-width: 100%;

        &-input {
            @include secondaryFont;
            /* prevent autoresize overflowing the container */
            max-width: 100%;

            background: #242f4e;
            border: unset;
            outline: none;
            margin: 0;
            padding: 0;
            color: white;
            font-size: 14px;
            font-weight: 400;

            &::-ms-clear {
                display: none;
            }
        }
    }
    
    @media screen and (min-width: 30em) {
        .react-tags__search {
            /* this will become the offsetParent for suggestions */
            position: relative;
        }
    }
    
    .react-tags__suggestions {
        @include secondaryFont;
        position: absolute;
        z-index: 999999;
        background: #242f4e;
        color: white;
        font-size: 14px;
        top: 100%;
        left: 0;
        width: 100%;

        ul {
            padding: 0;
            list-style: none;
        }

        li {
            padding: 6px 8px;

            .mark {
                text-decoration: underline;
                background: none;
                font-weight: 600;
            }

            &:hover {
                cursor: pointer;
            }

            &.is-active {
                background: #5a6974;
            }

            &.is-disabled {
                opacity: 0.5;
                cursor: auto;
            }
        }
    }
    
    @media screen and (min-width: 30em) {
        .react-tags__suggestions {
            width: 400px;
        }
    }
}
