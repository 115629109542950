.sc-configuration-page {
    .claiming-period {
        .content {
            display: grid;
            padding: 2rem 0;
            grid-row-gap: 1.5rem;
        }
    }

    .sale-contract-info2 {
        .content {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            grid-row-gap: 1.5rem;
            padding: 2rem 0;

            .row-wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 2rem;
                grid-row-gap: 1.5rem;
            }
        }
    }

    .sale-participants {
        .btn-group {
            display: grid;
            grid-template-columns: 7fr 3fr;
            grid-column-gap: 2rem;
            padding: 2rem 0 0;

            .simple-btn-wrapper {
                column-gap: .5rem;
                height: 45px !important;
                border-radius: 10px !important;
                background: $cyanGradientColor !important;
            }
        }
        .content {
            &.public {
                display: grid;
                grid-template-columns: 6fr 4fr;
                grid-row-gap: 1.5rem;
                grid-column-gap: 2rem;
            }
            padding: 2rem 0;

            .file-upload-wrapper {
                @include flexWrapper;
                justify-content: space-between;
                @include alignItemsCenter;
                border-radius: 13px;
                background-color: $bgNavbar;
                margin: 0.5rem 0;

                .filename-wrapper {
                    margin: 0.7rem 1.5rem;
                    color: $grayText;
                    font-size: 14px;

                    &.white {
                        color: white !important;
                    }

                    .imported-wrapper {
                        color: #3BDCB1;
                        font-size: 14px;

                    }
                }

                button {
                    @include flexWrapper;
                    @include alignItemsCenter;
                    background: $cyanGradientColor;
                    padding: 0.7rem 2rem;
                    border: none;
                    color: $textColor;
                    border-radius: 13px;
                    height: 44px;
                    cursor: pointer;
                    @include transition;

                    img {
                        margin-left: 1rem;
                    }

                    span {
                        font-size: 14px;
                        font-weight: bold;
                    }

                    &:hover {
                        opacity: 0.8;
                    }

                    &:disabled {
                        opacity: 0.6;
                        cursor: default;
                    }
                }
            }

            .note-mark-wrapper {
                display: flex;
                align-items: center;
                column-gap: .5rem;
            }

            .below-child-wrapper {
                @include flexWrapper;
                @include flexColumn;

                margin: 0.5rem 0;
                border-radius: 13px;
                background-color: #181F35;

                .file-upload-wrapper, .simple-input-container {
                    margin: 0 !important;
                }

                .select__control {
                    margin-bottom: 0 !important;
                }

                span.address-count {
                    @include secondaryFont;
                    font-size: 14px;
                    font-weight: 400;
                    color: #3BDCB1;
                    text-align: center;
                    margin-top: 1rem;
                }
            }
        }
    }
}
