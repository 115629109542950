.modal-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $bgSPMenuGradientColor;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: none;

    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        background: transparent;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        border-radius: 20px;
        outline: none;

        &.connect-wallet {
            max-width: 400px;
            border-radius: 0.5rem;
            overflow: hidden;
            background: $bgModal;
            position: relative;
            box-shadow: -10px -10px 15px rgba(50, 64, 102, 0.3), 10px 10px 15px rgba(1, 7, 19, 0.3);

            .header {
                height: 3em;
                color: $textColor;
                background: $cyanGradientColor;
                font-size: 24px;
                font-weight: 600;
                @include flexWrapper;
                @include justifyContentCenter;
                @include alignItemsCenter;
            }

            .content {
                padding: 2rem;
                border-radius: 0.6rem;
                display: grid;
                flex-direction: column;
                row-gap: 1rem;

                button {
                    background-color: rgba($primaryColor, 0.5);
                    border: none;
                    border-radius: 30px;
                    padding: 0.3rem 1rem;
                    height: 50px;
                    font-size: 18px;
                    font-weight: 700;
                    color: $textColor;
                    display: grid;
                    grid-template-columns: 1fr 4fr;
                    align-items: center;
                    justify-items: center;
                    @include transition;
                    line-height: 1.5;

                    &:hover:not(:disabled) {
                        background-color: $primaryColor;
                        cursor: pointer;
                    }

                    &:disabled {
                        opacity: .5;
                    }

                    span {
                        padding-right: 0.5rem;
                    }

                    &.disconnect {
                        grid-template-columns: 1fr;
                        background-color: #eee;
                        color: $primaryColor;
                        font-size: 22px;

                        &:hover:enabled {
                            background-color: $textColor;
                        }

                        &:hover:disabled {
                            background-color: #eee;
                        }
                    }
                }
            }
        }

        &.confirm-modal {
            max-width: 420px;
            border-radius: 0.5rem;
            overflow: hidden;
            background: $bgModal;
            position: relative;
            box-shadow: -10px -10px 15px rgba(50, 64, 102, 0.3), 10px 10px 15px rgba(1, 7, 19, 0.3);

            .header {
                height: 70px;
                color: $textColor;
                background: $cyanGradientColor;
                font-size: 18px;
                font-weight: 600;
                padding-top: 5px;
                @include flexWrapper;
                @include justifyContentCenter;
                @include alignItemsCenter;
            }

            .content {
                padding: 2rem;
                border-radius: 0.6rem;
                display: grid;
                flex-direction: column;
                row-gap: 1.5rem;

                .simple-input-container {
                    margin: 0;
                }

                .btn-group {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 1rem;

                    .btn-red {
                        background: linear-gradient(110.05deg, #fd7575 13.06%, rgba(253, 117, 117, 0.5) 84.95%);
                    }

                    button {
                        font-size: 14px;
                        font-weight: bold;
                        height: 40px;
                    }
                }
            }
        }

        &.publish-modal {
            max-width: 540px;
            border-radius: 0.5rem;
            overflow: hidden;
            background: $bgModal;
            position: relative;
            box-shadow: -2px -2px 3px rgba(50, 64, 102, 0.3), 3px 3px 5px rgba(1, 7, 19, 0.3);

            .content {
                padding: 2rem 3rem;
                border-radius: 0.6rem;
                display: grid;
                flex-direction: column;
                row-gap: 1.5rem;

                span {
                    @include secondaryFont;
                    font-size: 18px;
                    text-align: center;

                    &.warning {
                        font-weight: 600;
                    }
                }

                input {
                    @include secondaryFont;
                    box-shadow: 1px -1px 2px rgba(50, 64, 102, 0.3), -1px -1px 12px rgba(1, 7, 19, 0.3);
                    background: #242F4E;
                    border-radius: 13px;
                    border: unset;
                    outline: none;
                    font-size: 18px;
                    font-weight: 400;
                    height: 48px;
                    padding: 0 0.5rem 0 1.5rem;
                    width: 100%;
                }

                .btn-group {
                    display: grid;
                    grid-template-columns: 2fr 1fr;
                    grid-column-gap: 1rem;

                    .btn-red {
                        background: linear-gradient(110.05deg, #fd7575 13.06%, rgba(253, 117, 117, 0.5) 84.95%);
                    }

                    button {
                        font-size: 14px;
                        font-weight: bold;
                        height: 40px;
                    }
                }
            }
        }

        &.maintenance-modal {
            max-width: 540px;
            border-radius: 0.5rem;
            overflow: hidden;
            background: $bgModal;
            position: relative;
            box-shadow: -2px -2px 3px rgba(50, 64, 102, 0.3), 3px 3px 5px rgba(1, 7, 19, 0.3);

            .content {
                padding: 2rem;
                border-radius: 0.6rem;
                display: grid;
                flex-direction: column;
                row-gap: 1.5rem;

                textarea {
                    height: 150px !important;
                }

                .btn-group {
                    display: grid;
                    grid-template-columns: 1fr;

                    button {
                        font-size: 14px;
                        font-weight: bold;
                        height: 40px;
                    }
                }
            }
        }

        &.wrong-network {
            max-width: 370px;
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            background: $bgModal;
            box-shadow: -10px -10px 15px rgba(50, 64, 102, 0.3), 10px 10px 15px rgba(1, 7, 19, 0.3);

            .header {
                @include secondaryFont;
                color: $textColor;
                background: $cyanReverseGradientColor;
                line-height: 60px;
                font-size: 18px;
                font-weight: 600;
                text-align: center;
            }

            .content-wrapper {
                display: grid;
                grid-template-columns: minmax(0, 1fr);
                grid-row-gap: 2rem;
                padding: 2rem;
                justify-items: center;

                .switch-network {
                    background: linear-gradient(110.05deg, #3bdcb1 13.06%, #11a3b7 84.95%);
                    box-shadow: 0px 0px 0px 2px #29c1b1, -5px -5px 10px rgba(255, 255, 255, 0.1),
                        5px 10px 10px rgba(1, 7, 19, 0.2);
                    border-radius: 20px;
                    width: 200px;
                    padding: 1rem;
                    @include transition;

                    &:hover {
                        opacity: 0.7;
                    }

                    span {
                        padding: 0 0.5rem;
                        color: white;
                        font-size: 14px;
                    }
                }
            }
        }

        &.request-detail-modal {
            max-width: 640px;
            border-radius: 0.5rem;
            overflow: hidden;
            background: $bgModal;
            position: relative;
            box-shadow: -2px -2px 3px rgba(50, 64, 102, 0.3), 3px 3px 5px rgba(1, 7, 19, 0.3);

            .content {
                padding: 2rem 1.5rem 1rem;
                border-radius: 0.6rem;
                display: grid;
                flex-direction: column;
                row-gap: 1.5rem;

                .answer-header {
                    span:first-child {
                        font-weight: bold;
                        font-size: 16px;
                    }

                    span:last-child {
                        margin-left: .5rem;
                        font-size: 12px;
                    }
                }

                .answer-wrapper {
                    height: 50vh;
                    overflow: auto;
                    scroll-behavior: smooth;

                    &::-webkit-scrollbar {
                        width: 6px;
                    }

                    &::-webkit-scrollbar-track {
                        background: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: $bgNavbar;
                        border-radius: 3px;
                    }
                }

                .type-form-answers {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: .5rem;

                    span:first-child {
                        font-weight: bold;
                        font-size: 14px;
                        color: $primaryColor;
                    }

                    span:last-child {
                        font-weight: 400;
                        font-size: 14px;
                        font-family: $primaryFont;
                    }
                }
                

                .btn-group {
                    display: flex;
                    justify-content: flex-end;

                    button {
                        padding-left: 1rem;
                        padding-right: 1rem;
                        font-size: 14px;
                        font-weight: bold;
                        height: 40px;
                        width: fit-content;
                    }
                }
            }
        }

        &.create-account-modal {
            max-width: 540px;
            border-radius: 0.5rem;
            overflow: hidden;
            background: $bgModal;
            position: relative;
            box-shadow: -2px -2px 3px rgba(50, 64, 102, 0.3), 3px 3px 5px rgba(1, 7, 19, 0.3);

            .content {
                padding: 2rem 3rem;
                border-radius: 0.6rem;
                display: grid;
                flex-direction: column;
                row-gap: 1.5rem;

                span {
                    @include secondaryFont;
                    font-size: 18px;
                    text-align: center;

                    &.warning {
                        font-weight: 600;
                    }
                }

                input {
                    @include secondaryFont;
                    box-shadow: 1px -1px 2px rgba(50, 64, 102, 0.3), -1px -1px 12px rgba(1, 7, 19, 0.3);
                    background: #242F4E;
                    border-radius: 13px;
                    border: unset;
                    outline: none;
                    font-size: 18px;
                    font-weight: 400;
                    height: 48px;
                    padding: 0 0.5rem 0 1.5rem;
                    width: 100%;
                }

                .btn-group {
                    margin-left: auto;
                    display: flex;
                    align-items: flex-end;
                    width: 50%;

                    .btn-red {
                        margin-right: 1rem;
                        background: linear-gradient(110.05deg, #fd7575 13.06%, rgba(253, 117, 117, 0.5) 84.95%);
                    }

                    button {
                        font-size: 14px;
                        font-weight: bold;
                        height: 40px;
                    }
                }
            }
        }
    }
}

.close-button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
    right: 12px !important;
    top: 20px !important;
    width: 30px !important;
    height: 30px !important;
    background-color: transparent !important;
    border-radius: 50%;
    border: none !important;
    cursor: pointer;
    position: absolute;
    @include transition();

    svg {
        width: 24x;
        height: 24px;
    }

    &:hover {
        background-color: #ffffff40 !important;
    }
}
