.overview-page {
    .sale-overview, .pool-overview {
        .content {
            display: grid;
            grid-row-gap: 1.5rem;
            padding: 2em 0;

            div.column {
                display: grid;
                grid-template-columns: 1fr 1fr 2fr;
                grid-column-gap: 2rem;
            }
        }
    }

    .key-metrics {
        .content {
            display: grid;
            grid-row-gap: 1.5rem;
            padding: 2rem 0;
        }
    }

    .top-border-wrapper {
        border-top: 1px solid white;
        padding-top: 2rem;
    }
}
