.statistics-page {
    margin: 2rem;
    display: flex;    
    flex-direction: column;
    row-gap: 2rem;

    .column-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        
    }

    .chat-wrapper {
        display: grid;
        grid-template-columns: 1fr 2.5fr;
        grid-column-gap: 2rem;

        .right-panel {
            display: flex;
            flex-direction: column;
            row-gap: 2rem;

            .progress-bar-wrapper {
                padding-top: 2rem;
                .title-bar {
                    color: white;
                    font-weight: 600;
                    font-size: 18px;
                }

                .rootDiv {
                    height: 20px;
                }
            }

            .apexcharts-tooltip.apexcharts-theme-light {
                border: none;
                padding: 1rem;
                background: linear-gradient(100.07deg, rgba(41, 50, 77, 0.5) 18.98%, rgba(39, 50, 79, 0.5) 81.27%);
                box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
                span {
                    font-weight: 700;
                    font-size: 22px;
                }
            }
        }
    }

    .history-wrapper, .whitelist-wrapper, .raise-wrapper, .contribution-wrapper {
        .title {
            @include secondaryFont;
            padding: 3rem 0 1rem 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
        }
    }

    .whitelist-wrapper {
        .title {
            display: flex;
            align-items: center;
            column-gap: .5rem;
        }
        .content {
            margin: 0 auto;
            width: 80%;

            .percentage-circle-container {
                margin: 1.5rem 0 2rem;
                position: relative;
                display: flex;
                justify-content: center;
                svg {
                    position: relative;
                    width: 220px;
                    height: 220px;
                    z-index: 1000;
        
                    circle {
                        width: 100%;
                        height: 100%;
                        fill: none;
                        stroke: rgba(black, 0);
                        stroke-width: 12;
                        stroke-linecap: round;
                        transform: translate(5px, 10px);
                        transition: 1s;
        
                        &:nth-child(2) {
                            stroke-dasharray: 628;
                            stroke: #3BDCB1;
                        }
                    }
                }

                .percent-label {
                    position: absolute;
                    left: 105px;
                    top: 75px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    span {
                        &.number {
                            font-size: 30px;
                            font-weight: 700;
                        }

                        &.caption {
                            font-size: 16px;
                            font-weight: 40px;
                        }
                    }
                }
            }
        }
        
    }

    .history-wrapper {
        width: 100%;
        .content {
            .table-wrapper {
                .table-header {
                    border-bottom: 1px solid white;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 2fr;
                    padding: 1rem 0;

                    span {
                        @include secondaryFont;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
    
                .table-body {
                    .table-row {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 2fr;
                        
                        padding: 1rem 0;

                        span {
                            @include secondaryFont;
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
                }
            }
            
            .pagination-wrapper {
                padding: 1rem 0;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    color: rgba(255, 255, 255, 0.4);
                    cursor: pointer;
                    font-size: 24px;
                    padding: 0 .5rem;

                    &:hover {
                        color: rgba(255, 255, 255, 0.8);
                    }
                }

                input {
                    @include secondaryFont;
                    background: #242f4e;
                    border-radius: 10px;
                    border: unset;
                    outline: none;
                    color: $grayText;
                    height: 48px;
                    width: 100px;
                    font-size: 20px;
                    font-weight: 400;
                    text-align: center;
                    margin: 0 .5rem;
                }
            }
        }
    }
}
