.sort-table {
    &-row {
        display: grid;
        grid-template-columns: 4fr 2fr 3fr 2fr 3fr 3fr 3fr 3fr;
        padding: 1rem;

        img {
            @include transition;
        }

        &:nth-of-type(odd) {
            background-color: $bgTableDark;
        }

        &:nth-of-type(even) {
            background-color: $bgTableLight;
        }

        &:first-of-type {
            border-radius: 10px 10px 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 10px 10px;
        }
    }

    &-cell {
        @include flexWrapper;
        @include alignItemsCenter;

        &.header {
            cursor: pointer;

            &:last-of-type {
                @include justifyContentCenter;
            }
        }

        font-size: 14px;
        color: white;

        a {
            display: block;
            width: 100%;

            .action-button {
                width: 90px;
                display: block;
                margin: 0 auto;
                border-radius: 20px;
                padding: 0.5rem 0;
                color: white !important;
                text-align: center;
                background: linear-gradient(180deg, #22b9b5 0%, #37d6b2 100%);
                box-shadow: 0px 0px 0px 2px #1a2238, -5px -5px 10px rgba(255, 255, 255, 0.1),
                    5px 10px 10px rgba(1, 7, 19, 0.2);
                cursor: pointer;
            }
        }

        &:nth-last-child(2) {
            @include justifyContentCenter;
        }
    }

    &-header {
        span {
            margin-right: 0.5rem;
            color: white;
            font-weight: bold;
        }
        border-bottom: 1px solid #bac6d2 !important;

        @include breakpoint(mobile) {
            display: none;
        }
    }
}
