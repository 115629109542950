#pool.page {
    .tab-header {
        span {
            width: calc(100% / 4);
        }

        .animated-bg {
            width: calc(100% / 4);
        }
    }

    .pool-configuration-page {
        .configuration-wrapper {
            .content {
                display: grid;
                grid-row-gap: 1.5rem;
                padding: 2em 0;
    
                div.column {
                    display: grid;
                    align-items: center;
                    grid-template-columns: 3fr 6fr 1fr;
                    grid-column-gap: 2rem;

                    .logo-preview {
                        max-height: 85px;
    
                        img {
                            height: 80px;
                            contain: content;
                        }
                    }
                }
            }
        }
    }

    .pool-deployment-page {
        display: grid;
        grid-row-gap: 2rem;
        padding: 2em 0;

        .initial-deployment-wrapper, .increase-reward-wrapper {
            .content {
                display: grid;
                grid-row-gap: 1.5rem;
                padding: 2em 0 0;
    
                div.column {
                    display: grid;
                    align-items: center;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 2rem;
                }
            }
        }
    }

    .reward-configuration-page {
        display: grid;
        grid-row-gap: 2rem;
        padding: 2em 0;

        button.remove {
            background: $redGradientColor !important;
        }

        .reward-accumulation-wrapper, .reward-info-wrapper {
            .content {
                display: grid;
                grid-row-gap: 1.5rem;
                padding: 2em 0 0;
            }
        }

        .reward-accumulation-wrapper {
            div.column {
                display: grid;
                align-items: center;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 2rem;
            }
        }

        .reward-info-wrapper {
            div.column {
                display: grid;
                align-items: center;
                grid-template-columns: 3fr 7fr;
                grid-column-gap: 2rem;

                .logo-wrapper {
                    display: grid;
                    align-items: center;
                    grid-template-columns: 6fr 1fr;
                    grid-column-gap: 2rem;

                    .logo-preview {
                        max-height: 85px;
    
                        img {
                            height: 80px;
                            contain: content;
                        }
                    }
                }
            }
        }
    }
}
