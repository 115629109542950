.project-page, .sales-page, .lmPools-page, .logs-page, .requests-page, .quotes-page, .accounts-page {
    .page-table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 2rem 0 1rem 0;
        border-bottom: 1px dashed white;
        margin-bottom: 1rem;

        span {
            font-size: 24px;
            font-weight: bold;
            color: $primaryColor; 
            @include secondaryFont;
        }

        .simple-btn-wrapper {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

.page {
    padding: 3rem 0;

    &-banner {
        margin-bottom: 3rem;
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-bottom: 2rem;
        border-bottom: 1px dashed white;

        .page-title {
            display: flex;
            align-items: center;

            .title {
                h2 {
                    @include secondaryFont;
                    margin-right: 1rem;
                }
            }

            .btn-group {
                display: flex;
                align-items: center;
                margin: 0 0.5rem;

                .btn-save {
                    background: linear-gradient(180deg, #23bbb4 0%, #36d5b2 100%);
                    border-radius: 100px;
                    width: 100px;
                    margin: 0 0.5rem;
                }

                .btn-delete {
                    background: linear-gradient(
                        110.05deg,
                        rgba(253, 117, 117, 0.8) 13.06%,
                        rgba(253, 117, 117, 0.5) 84.95%
                    );
                    border-radius: 100px;
                    width: 100px;
                    margin: 0 0.5rem;
                }

                button {
                    font-size: 14px;
                    font-weight: bold;
                }
            }

            .toggle {
                @include secondaryFont;
                display: flex;
                align-items: center;

                margin: 0 1rem;
            }
        }

        .back {
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
                margin-left: 1rem;
                font-weight: bold;
            }
        }
    }  

    &-content {
        .tab-container {
            margin-top: 2rem;
            width: 100%;

            @include breakpoint(phablet) {
                border-top: 1px dashed;
                padding-top: 3rem;
            }

            .tab-header {
                display: flex;
                background: #10172a;
                border-radius: 30px;
                position: relative;
                border: 4px solid #10172a;

                span {
                    text-align: center;
                    font-weight: 500;
                    font-size: 14px;
                    color: white;
                    padding: 10px 0;
                    z-index: 2;
                    cursor: pointer;
                    @include transition;

                    &.disabled {
                        pointer-events: none;
                    }

                    &.active {
                        font-weight: bold;
                    }
                }

                .animated-bg {
                    background: $primaryGradientColor;
                    border-radius: 30px;
                    position: absolute;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
                }
            }
        }
    }
}

.content {
    span {
        @include secondaryFont;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
    }

    .custom-tooltip {
        span {
            font-size: 14px !important;
        }
    }

    span:first-child, sup {
        cursor: pointer;
    }

    sup:nth-child(2) {
        color: #C6E547;
    }

    sup:nth-child(3) {
        color: #FF9292;
    }
}

.overview-page, .whitelist-page, .schedule-page, .sc-configuration-page, .deployment-page, .pool-configuration-page, .pool-deployment-page, .reward-configuration-page {
    .title {
        padding: 3rem 0 1rem 0;
        border-bottom: 1px solid white;

        span {
            @include secondaryFont;
            font-weight: 600;
            font-size: 21px;
            line-height: 25px;
        }
    }

    .horizontal-divider {
        margin: 2rem 0;
        display: block;
        height: 0px;
        width: 100%;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
    }
}

.vertical-divider {
    display: block;
    height: 40px;
    width: 0px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.save-next-wrapper {
    display: flex;
    justify-content: flex-end;

    .simple-btn-wrapper {
        width: 150px !important;
        padding-left: .5rem;
        padding-right: .5rem;
    }
}

.btn-deployment {
    height: 66px;
    border-radius: 13px;
    font-size: 18px;
    font-weight: bold;
}

div[role=status] {
    word-break: break-all;
}