#layout-wrapper {
    color: $textColor;
    @include flexWrapper;
    @include flexColumn;
    min-height: 100vh;
    position: relative;

    .top-mask {
        background-image: url('/assets/png/top-mask.webp');
        background-repeat: no-repeat;
        width: 1100px;
        height: 1100px;
        left: 0;
        top: 0;
        background-size: cover;
        position: absolute;
        z-index: -1;

        @include breakpoint(laptop) {
            display: none;
        }

        &.right {
            background-image: url('/assets/png/top-mask3.webp');

            left: unset !important;
            right: -450px !important;
            top: -450px !important;
        }
    }

    header {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 0 0 0 20px;
        background-color: rgba($bgPrimary, 0.9);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        padding: 0.5rem 2.5rem;

        .header-container {
            margin: 0 auto;
            @include flexWrapper;
            justify-content: space-between;
            @include alignItemsCenter;

            .logo-wrapper,
            .nav-wrapper {
                @include flexWrapper;
                @include alignItemsCenter;

                .divider {
                    height: 20px;
                    width: 2px;
                    background-color: #ffffff;
                    opacity: 0.5;
                }
            }

            .nav-wrapper {
                column-gap: .5rem;

                .nav-divider-wrapper {
                    @include flexWrapper;
                    @include alignItemsCenter;
                    column-gap: .5rem;
                }

                .nav-item-wrapper {
                    position: relative;

                    .nav-item {
                        position: relative;
                        @include flexWrapper;
                        @include alignItemsCenter;
                        column-gap: .5rem;
                        padding: .4rem .75rem;
                        border-radius: 1rem;
                        z-index: 99;

                        img {
                            @include transition;
                            transform: rotate(0deg);
                        }
                        
                        &:hover {
                            @include transition;
                            background: $cyanGradientColor;

                            img {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    
                    .nested-sub-nav {
                        position: absolute;
                        background: $bgPrimary;
                        box-shadow: 2px 2px 3px rgb(50 64 102 / 30%), 10px 10px 15px rgb(1 7 19 / 30%);
                        pointer-events: none;

                        top: 0px;
                        left: 0;
                        height: 0;
                        opacity: 0;

                        padding: 1.75rem 0 .5rem;
                        border-radius: 1rem;
                        width: 100%;

                        @include flexWrapper;
                        @include flexColumn;
                        @include alignItemsStart;

                        @include transitionSlow;

                        a {
                            width: 100%;
                        }

                        .nav-caption {
                            padding: .5rem 0 0.5rem 0.75rem;
                            margin-top: .5rem;
                            font-weight: 500;
                            width: 100%;
                            border-radius: .5rem;
                            @include transition;

                            &:hover {
                                background: $cyanGradientColor;
                            }
                        }
                    }

                    &:hover {
                        .nested-sub-nav {
                            height: auto;
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
                
                .nav-caption {
                    cursor: pointer;
                    font-size: 18px;
                    font-family: $primaryFont !important;
                    font-weight: bold;

                    &:hover {
                        background-color: $cyanGradientColor;
                        border-radius: .5rem;
                    }
                }
            }

            .logo-wrapper {
                column-gap: 1rem;
                opacity: 1;
                width: unset;
                margin-right: 0.5rem;

                a {
                    @include flexWrapper;
                    @include alignItemsCenter;
                    @include justifyContentCenter;
                }

                span.site-name {
                    @include secondaryFont;
                    font-size: 30px;
                    font-weight: lighter;
                }
            }
        }
    }

    .contents-wrapper {
        padding: 5rem 2rem;

        @include breakpoint(phablet) {
            padding: 0 1.5rem;
        }

        @include breakpoint(mobile) {
            padding: 0 1rem;
        }

        .container {
        }

        .navbar-wrapper {
            @include flexWrapper;
            @include flexColumn;
            width: 226px;
            border-radius: 20px 0 20px 20px;
            background: rgba($bgNavbar, 0.4);

            padding: 66px 1.5rem 0;

            font-family: 'Source Sans Pro';

            .navbar-content {
                border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
                border-top: 1px dashed rgba(255, 255, 255, 0.5);
                padding: 1rem 0;
                @include flexWrapper;
                @include flexColumn;
            }

            .social-wrapper {
                padding: 1rem 0.5rem;
                @include flexWrapper;
                justify-content: space-between;

                a {
                    @include transition;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }

            @include breakpoint(laptop) {
                display: none;
            }
        }
    }

    footer {
        position: relative;
        margin-top: auto;
        padding: 1rem 2.5rem 3rem;
        @include flexWrapper;
        justify-content: space-between;
        @include alignItemsEnd;

        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        @include breakpoint(phablet) {
            @include flexWrapper;
            @include alignItemsCenter;
            flex-direction: column-reverse;
        }

        .copyrights {
            @include flexWrapper;
            @include flexColumn;
            @include alignItemsStart;

            @include breakpoint(phablet) {
                @include alignItemsCenter;
            }
        }
    }
}
