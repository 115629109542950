.deployment-page {
    .sale-deployment,
    .claim-deployment {
        .content {
            display: grid;
            grid-row-gap: 1.5rem;
            padding: 2rem 0;

            .upload-result {
                @include secondaryFont;
                color: #3bdcb1;
                font-size: 12px;
                font-weight: 400;
                margin-left: 0.5rem;
            }

            .deploy-result {
                span {
                    @include secondaryFont;
                    color: #3bdcb1;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }
    }

    .bg-check-wrapper {
        .check-table {
            &-row {
                display: grid;
                grid-template-columns: 1.5fr 2fr 2fr;
                align-items: center;

                span {
                    color: white !important;
                    font-size: 14px;
                    font-weight: 400;
                    padding: 1.5rem 1rem;
                }

                &:nth-child(even) {
                    background: linear-gradient(100.07deg, rgba(41, 50, 77, 0.5) 18.98%, rgba(39, 50, 79, 0.5) 81.27%);
                }

                &:nth-child(odd) {
                    background: #171e34;
                }
            }

            &-header {
                border-bottom: 2px solid #c4c4c4;
                border-radius: 15px 15px 0 0;
            }

            &-footer {
                border-top: 2px solid #c4c4c4;
                border-radius: 0 0 15px 15px;

                .simple-btn-wrapper {
                    width: 98%;
                    box-shadow: 0px 0px 0px 2px #1a2238, -5px -5px 10px rgba(255, 255, 255, 0.1),
                        5px 10px 10px rgba(1, 7, 19, 0.2);

                    &.add-contributors-btn {
                        grid-column-start: 2;
                        grid-column-end: 4;
                        width: 50% !important;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }

    .instruction {
        .title {
            padding: 3rem 0 1rem 0;
            border-bottom: 1px solid white;

            span {
                @include secondaryFont;
                font-weight: 600;
                font-size: 21px;
                line-height: 25px;
            }
        }

        .contents {
            color: $bgGray;
            padding-top: 1rem;
            h3 {
                margin-bottom: 1rem;
            }
            > li {
                margin-left: 1rem;
                ul {
                    li {
                        margin: 0.5rem 0;
                    }
                }
            }
        }
    }
}
